<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper create-lead">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">Add lead</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn tile depressed color="white--text" class="blue darken-4">Save</v-btn>
				</v-flex>
				<div class="drawer-content pt-5 px-5">
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">Contact person</label>
						</div>
						<v-autocomplete
							:items="contactList"
							depressed
							item-value="id"
							hide-details
							outlined
							v-model="lead.contact"
						>
							<template v-slot:item="data">
								<template>
									<v-list-item-avatar>
										<img :src="data.item.image" />
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title v-html="data.item.name"></v-list-item-title>
									</v-list-item-content>
								</template>
							</template>
						</v-autocomplete>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">Pipeline</label>
						</div>
						<v-autocomplete
							:items="userList"
							depressed
							hide-details
							outlined
							v-model="lead.pipeline"
						></v-autocomplete>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">lead stage</label>
						</div>
						<v-autocomplete
							:items="leadStage"
							depressed
							hide-details
							outlined
							v-model="lead.lead_stage"
						></v-autocomplete>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">Amount</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="lead.amount"></v-text-field>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">Close Date</label>
						</div>
						<v-text-field depressed hide-details outlined v-model="lead.state"></v-text-field>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">lead owner</label>
						</div>
						<v-autocomplete
							:items="contactList"
							depressed
							hide-details
							outlined
							v-model="lead.owner"
						></v-autocomplete>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">lead Type</label>
						</div>
						<v-autocomplete
							:items="leadType"
							depressed
							hide-details
							outlined
							v-model="lead.type"
						></v-autocomplete>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">Priority</label>
						</div>
						<v-autocomplete
							:items="priority"
							depressed
							hide-details
							outlined
							v-model="lead.priority"
						></v-autocomplete>
					</div>
					<v-divider></v-divider>
					<div class="pb-3">
						<span class="font-level-3-bold primary--text">Associate lead width</span>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">Company</label>
						</div>
						<v-autocomplete
							:items="companyName"
							depressed
							hide-details
							outlined
							v-model="lead.associate_company"
						></v-autocomplete>
						<v-checkbox
							class="size-18 mt-2 pa-0"
							hide-details
							label="Add timeline activity from BThrust to this lead"
						>
						</v-checkbox>
					</div>
					<div class="mb-3">
						<div class="d-flex pt-1">
							<label class="fw-500">Contact</label>
						</div>
						<v-autocomplete
							:items="userList"
							depressed
							hide-details
							outlined
							v-model="lead.associate_contact"
						></v-autocomplete>
						<v-checkbox
							class="size-18 mt-2 pa-0"
							hide-details
							label="Add timeline activity from BThrust to this lead"
						>
						</v-checkbox>
					</div>
				</div>
			</div>
		</v-navigation-drawer>
	</div>
</template>
<style>
/* .v-dialog__content.v-dialog__content--active {
z-index: 201 !important;
} */
</style>
<script>
export default {
	name: "Createlead",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formValid: true,
			lead: {
				contact: null,
				name: null,
				owner: null,
				industry: [],
				city: null,
				state: null,
				postal_code: null,
				no_of_employees: null,
				revenue: null,
				description: null,
			},
			companyName: ["bthrust"],
			leadStage: [],
			leadType: [],
			priority: ["high", "medium", "low"],
			userList: [],
			contactList: [],
		};
	},
};
</script>
<style scoped></style>
